:root.dark-theme {
  --black: 0 0 0;
  --white: 255 255 255;
  --red-50: 254 242 242;
  --red-100: 254 226 226;
  --red-200: 254 202 202;
  --red-300: 252 165 165;
  --red-400: 248 113 113;
  --red-500: 239 68 68;
  --red-600: 220 38 38;
  --red-700: 185 28 28;
  --red-800: 153 27 27;
  --red-900: 127 29 29;
  --red-950: 69 10 10;
  --yellow-50: 254 252 232;
  --yellow-100: 254 249 195;
  --yellow-200: 254 240 138;
  --yellow-300: 253 224 71;
  --yellow-400: 250 204 21;
  --yellow-500: 234 179 8;
  --yellow-600: 202 138 4;
  --yellow-700: 161 98 7;
  --yellow-800: 133 77 14;
  --yellow-900: 113 63 18;
  --yellow-950: 66 32 6;
  --orange-50: 255 247 237;
  --orange-100: 255 237 213;
  --orange-200: 254 215 170;
  --orange-300: 253 186 116;
  --orange-400: 251 146 60;
  --orange-500: 249 115 22;
  --orange-600: 234 88 12;
  --orange-700: 194 65 12;
  --orange-800: 154 52 18;
  --orange-900: 124 45 18;
  --orange-950: 67 20 7;
  --zinc-50: 250 250 250;
  --zinc-100: 244 244 245;
  --zinc-200: 228 228 231;
  --zinc-300: 212 212 216;
  --zinc-400: 161 161 170;
  --zinc-500: 113 113 122;
  --zinc-600: 82 82 91;
  --zinc-700: 63 63 70;
  --zinc-800: 39 39 42;
  --zinc-900: 24 24 27;
  --zinc-950: 9 9 11;
  --rose-50: 255 241 242;
  --rose-100: 255 228 230;
  --rose-200: 254 205 211;
  --rose-300: 253 164 175;
  --rose-400: 251 113 133;
  --rose-500: 244 63 94;
  --rose-600: 225 29 72;
  --rose-700: 190 18 60;
  --rose-800: 159 18 57;
  --rose-900: 136 19 55;
  --rose-950: 76 5 25;
  --pink-50: 253 242 248;
  --pink-100: 252 231 243;
  --pink-200: 251 207 232;
  --pink-300: 249 168 212;
  --pink-400: 244 114 182;
  --pink-500: 236 72 153;
  --pink-600: 219 39 119;
  --pink-700: 190 24 93;
  --pink-800: 157 23 77;
  --pink-900: 131 24 67;
  --pink-950: 80 7 36;
  --fuchsia-50: 253 244 255;
  --fuchsia-100: 250 232 255;
  --fuchsia-200: 245 208 254;
  --fuchsia-300: 240 171 252;
  --fuchsia-400: 232 121 249;
  --fuchsia-500: 217 70 239;
  --fuchsia-600: 192 38 211;
  --fuchsia-700: 162 28 175;
  --fuchsia-800: 134 25 143;
  --fuchsia-900: 112 26 117;
  --fuchsia-950: 112 26 117;
  --purple-50: 250 245 255;
  --purple-100: 243 232 255;
  --purple-200: 233 213 255;
  --purple-300: 216 180 254;
  --purple-400: 192 132 252;
  --purple-500: 168 85 247;
  --purple-600: 147 51 234;
  --purple-700: 126 34 206;
  --purple-800: 107 33 168;
  --purple-900: 88 28 135;
  --purple-950: 59 7 100;
  --violet-50: 245 243 255;
  --violet-100: 237 233 254;
  --violet-200: 221 214 254;
  --violet-300: 196 181 253;
  --violet-400: 167 139 250;
  --violet-500: 139 92 246;
  --violet-600: 124 58 237;
  --violet-700: 109 40 217;
  --violet-800: 91 33 182;
  --violet-900: 76 29 149;
  --violet-950: 46 16 101;
  --indigo-50: 238 242 255;
  --indigo-100: 224 231 255;
  --indigo-200: 199 210 254;
  --indigo-300: 165 180 252;
  --indigo-400: 129 140 248;
  --indigo-500: 99 102 241;
  --indigo-600: 79 70 229;
  --indigo-700: 67 56 202;
  --indigo-800: 55 48 163;
  --indigo-900: 49 46 129;
  --indigo-950: 30 27 75;
  --blue-50: 239 246 255;
  --blue-100: 219 234 254;
  --blue-200: 191 219 254;
  --blue-300: 147 197 253;
  --blue-400: 96 165 250;
  --blue-500: 59 130 246;
  --blue-600: 37 99 235;
  --blue-700: 29 78 216;
  --blue-800: 30 64 175;
  --blue-900: 30 58 138;
  --blue-950: 23 37 84;
  --sky-50: 240 249 255;
  --sky-100: 224 242 254;
  --sky-200: 186 230 253;
  --sky-300: 125 211 252;
  --sky-400: 56 189 248;
  --sky-500: 14 165 233;
  --sky-600: 2 132 199;
  --sky-700: 3 105 161;
  --sky-800: 7 89 133;
  --sky-900: 12 74 110;
  --sky-950: 8 47 73;
  --cyan-50: 236 254 255;
  --cyan-100: 207 250 254;
  --cyan-200: 165 243 252;
  --cyan-300: 103 232 249;
  --cyan-400: 34 211 238;
  --cyan-500: 6 182 212;
  --cyan-600: 8 145 178;
  --cyan-700: 14 116 144;
  --cyan-800: 21 94 117;
  --cyan-900: 22 78 99;
  --cyan-950: 8 51 68;
  --teal-50: 240 253 250;
  --teal-100: 204 251 241;
  --teal-200: 153 246 228;
  --teal-300: 94 234 212;
  --teal-400: 45 212 191;
  --teal-500: 20 184 166;
  --teal-600: 13 148 136;
  --teal-700: 15 118 110;
  --teal-800: 17 94 89;
  --teal-900: 19 78 74;
  --teal-950: 4 47 46;
  --emerald-50: 236 253 245;
  --emerald-100: 209 250 229;
  --emerald-200: 167 243 208;
  --emerald-300: 110 231 183;
  --emerald-400: 52 211 153;
  --emerald-500: 16 185 129;
  --emerald-600: 5 150 105;
  --emerald-700: 4 120 87;
  --emerald-800: 6 95 70;
  --emerald-900: 6 78 59;
  --emerald-950: 2 44 34;
  --green-50: 240 253 244;
  --green-100: 220 252 231;
  --green-200: 187 247 208;
  --green-300: 134 239 172;
  --green-400: 74 222 128;
  --green-500: 34 197 94;
  --green-600: 22 163 74;
  --green-700: 21 128 61;
  --green-800: 22 101 52;
  --green-900: 20 83 45;
  --green-950: 5 46 22;
  --lime-50: 247 254 231;
  --lime-100: 236 252 203;
  --lime-200: 217 249 157;
  --lime-300: 190 242 100;
  --lime-400: 163 230 53;
  --lime-500: 132 204 22;
  --lime-600: 101 163 13;
  --lime-700: 77 124 15;
  --lime-800: 63 98 18;
  --lime-900: 54 83 20;
  --lime-950: 26 46 5;
  --amber-50: 255 251 235;
  --amber-100: 254 243 199;
  --amber-200: 253 230 138;
  --amber-300: 252 211 77;
  --amber-400: 251 191 36;
  --amber-500: 245 158 11;
  --amber-600: 217 119 6;
  --amber-700: 180 83 9;
  --amber-800: 146 64 14;
  --amber-900: 120 53 15;
  --amber-950: 69 26 3;
  --slate-50: 248 250 252;
  --slate-100: 241 245 249;
  --slate-200: 226 232 240;
  --slate-300: 203 213 225;
  --slate-400: 148 163 184;
  --slate-500: 100 116 139;
  --slate-600: 71 85 105;
  --slate-700: 51 65 85;
  --slate-800: 30 41 59;
  --slate-900: 15 23 42;
  --slate-950: 2 6 23;
  --state-on-light-1: #264d5e0a;
  --state-on-light-2: #264d5e14;
  --state-on-light-3: #264d5e1f;
  --state-on-light-4: #264d5e3d;
  --state-on-dark-1: #ffffff17;
  --state-on-dark-2: #ffffff29;
  --state-on-dark-3: #ffffff1f;
  --state-on-dark-4: #ffffff3d;
  --azure-50: 237 247 255;
  --azure-100: 224 242 255;
  --azure-200: 195 228 254;
  --azure-300: 144 204 254;
  --azure-400: 83 178 253;
  --azure-500: 48 161 253;
  --azure-600: 0 132 236;
  --azure-700: 2 112 202;
  --azure-800: 1 87 157;
  --azure-900: 2 67 121;
  --azure-950: 0 43 75;
  --brand-50: 237 247 255;
  --brand-100: 224 242 255;
  --brand-200: 195 228 254;
  --brand-300: 144 204 254;
  --brand-400: 83 178 253;
  --brand-500: 48 161 253;
  --brand-600: 0 132 236;
  --brand-700: 2 112 202;
  --brand-800: 1 87 157;
  --brand-900: 2 67 121;
  --brand-950: 0 43 75;
  --iris-50: 244 245 255;
  --iris-100: 232 231 255;
  --iris-300: 181 179 255;
  --iris-600: 115 83 241;
  --border-primary: 82 82 91;
  --border-secondary: 63 63 70;
  --border-mute: 39 39 42;
  --border-brand: 48 161 253;
  --border-error: 244 63 94;
  --border-success: 21 128 61;
  --border-secondary-inverse: 228 228 231;
  --border-secondary-hovered: 113 113 122;
  --state-hovered-primary: #ffffff1f;
  --state-hovered-secondary: #ffffff17;
  --state-hovered-on-brand: #ffffff1f;
  --state-active-primary: #ffffff3d;
  --state-active-secondary: #ffffff29;
  --text-primary: 255 255 255;
  --text-primary-on-brand: 255 255 255;
  --text-primary-inverse: 24 24 27;
  --text-secondary: 212 212 216;
  --text-tertiary: 113 113 122;
  --text-mute: 82 82 91;
  --text-error: 244 63 94;
  --text-white: 255 255 255;
  --text-black: 24 24 27;
  --text-brand: 0 132 236;
  --background-primary: 9 9 11;
  --background-secondary: 39 39 42;
  --background-mute: 24 24 27;
  --background-error-low: 76 5 25;
  --background-primary-inverse: 255 255 255;
  --background-success-high: 22 163 74;
  --background-elevation-level1: 39 39 42;
  --background-brand: 0 132 236;
  --background-brand-mute: 2 67 121;
  --foreground-primary: 255 255 255;
  --foreground-primary-on-brand: 255 255 255;
  --foreground-primary-inverse: 24 24 27;
  --foreground-secondary: 212 212 216;
  --foreground-mute: 63 63 70;
  --foreground-primary-on-brand-mute: 113 113 122;
  --foreground-error: 225 29 72;
  --foreground-error-mute: 136 19 55;
  --foreground-brand: 48 161 253;
  --foreground-white: 255 255 255;
  --foreground-black: 24 24 27;
  --foreground-tertiary: 113 113 122;
  --utility-rose-lighter: 76 5 25;
  --utility-rose-medium: 159 18 57;
  --utility-rose-lightest: 76 5 25;
  --utility-rose-dark: 244 63 94;
  --utility-violet-dark: 167 139 250;
  --utility-violet-lighter: 46 16 101;
  --utility-indigo-lighter: 30 27 75;
  --utility-indigo-dark: 129 140 248;
  --utility-yellow-lighter: 66 32 6;
  --utility-yellow-dark: 234 179 8;
  --utility-yellow-lightest: 66 32 6;
  --utility-yellow-medium: 133 77 14;
  --utility-yellow-darkest: 253 224 71;
  --utility-zinc-lighter: 39 39 42;
  --utility-zinc-dark: 161 161 170;
  --utility-zinc-lightest: 39 39 42;
  --utility-zinc-medium: 63 63 70;
  --utility-pink-lighter: 80 7 36;
  --utility-pink-dark: 236 72 153;
  --utility-brand-lighter: 0 43 75;
  --utility-brand-dark: 48 161 253;
  --utility-brand-lightest: 0 43 75;
  --utility-brand-medium: 2 112 202;
  --utility-green-lighter: 5 46 22;
  --utility-green-dark: 34 197 94;
  --utility-green-lightest: 5 46 22;
  --utility-green-medium: 21 128 61;
  --utility-green-darkest: 134 239 172;
  --scroll-default: 82 82 91;
  --segment-control-segment: 63 63 70;
  --toggle-background: 63 63 70;
  --dim: #6b7280bf;
  --snackbar-background: #52525bb3;
  --snackbar-foreground-error: 225 29 72;
  --snackbar-foreground-positive: 22 163 74;
  --elevation-level1-gradient: linear-gradient(0deg, rgba(#27272a, 0) 100%, rgba(#27272a, 1) 0%);
  --tabs-border-selected: 255 255 255;
  --spacing-gradient-on-background-primary: linear-gradient(0deg, #09090bff 0%, #09090b00 100%);
  --spacing-gradient-on-background-secondary: linear-gradient(0deg, #27272aff 0%, #27272a00 100%);
  --spacing-gradient-on-level1: linear-gradient(0deg, #27272aff 0%, #27272a00 100%);
}
