@font-face {
  font-display: swap;
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 300;
  src:
    url(GmarketSansTTFLight.ttf) format('ttf'),
    url(GmarketSansLight.otf) format('opentype');
}

@font-face {
  font-display: swap;
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 500;
  src:
    url(GmarketSansTTFMedium.ttf) format('ttf'),
    url(GmarketSansMedium.otf) format('opentype');
}

@font-face {
  font-display: swap;
  font-family: 'Gmarket Sans';
  font-style: normal;
  font-weight: 700;
  src:
    url(GmarketSansTTFBold.ttf) format('ttf'),
    url(GmarketSansBold.otf) format('opentype');
}
