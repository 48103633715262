.react-datepicker .react-datepicker__header {
  background-color: white;
  border-bottom: 1px solid #ffb601 !important;
}

.react-datepicker__tab-loop .react-datepicker {
  border: none;
  border-radius: 8px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.react-datepicker__tab-loop .react-datepicker .react-datepicker__triangle {
  display: none;
}

.react-datepicker__tab-loop .react-datepicker .react-datepicker__day--selected {
  background-color: #ffb601 !important;
  color: white !important;
}

.react-datepicker__tab-loop .react-datepicker-popper {
  padding: 0 !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #212836;
  padding: 8px 16px;
  border: 1px solid #e2e4e9;
  border-radius: 4px;
  width: 116px;
  height: 40px;
}

.react-datepicker-wrapper .react-datepicker__input-container input::placeholder {
  color: #9199ab;
}

.react-datepicker .react-datepicker__navigation {
  top: 8px;
}

.react-datepicker .react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker .react-datepicker__day-name:first-child {
  color: #dd0000;
}

.react-datepicker .react-datepicker__day-name:last-child {
  color: #2448ff;
}

.react-datepicker .react-datepicker__week .react-datepicker__day:first-child {
  color: #dd0000;
}

.react-datepicker .react-datepicker__week .react-datepicker__day:last-child {
  color: #2448ff;
}
