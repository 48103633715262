@import url('../../../../packages/lbox-design-system/token/color/dist/lbox/stylesheet/dark.css');
@import url('../../../../packages/lbox-design-system/token/color/dist/lbox/stylesheet/light.css');

.range-slider .range-slider__thumb {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid rgb(var(--brand-600));
  box-shadow: 0px 2px 2px #d1d5db;
}

.range-slider .range-slider__range {
  height: 6px;
  background-color: rgb(var(--brand-600));
  cursor: default;
  pointer-events: none;
}

.range-slider {
  z-index: 0;
}
